import { useStaticQuery, graphql } from "gatsby"
export const useSiteMetadata = () => {
  const { site } = useStaticQuery(
    graphql`
      query SiteMetaData {
        site {
          siteMetadata {
            application_api_key
            application_id
            product_url
            cache_duration
            indexName
            siteUrl
          }

        }
      }
    `
  )
  return site.siteMetadata
}